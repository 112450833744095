{**********************************************************************************
 * Copyright (C) 1990-2024 Systematic Marketing Ltd (SMXi Software)
 *
 * All code contained within this file is copyrighted and may only be used by
 * third parties with written permission from Systematic Marketing Ltd.
 *
 * For permissions, please contact us at: info@smxi.com
 * Visit our website for more information: https://smxi.com/codecopyright.html
 **********************************************************************************}

unit GadgetPage;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  BaseSitePage,
  Data.DB,
  WEBLib.DB,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.DBCtrls,
  Vcl.Controls,
  WEBLib.ExtCtrls,
  Gadget.ReturnTypes,
  Gadget.Enumerations,
  WEBLib.Lists,
  WEBLib.WebCtrls,
  WEBLib.Grids,
  SelectPage;

type
  TGadgetForm = class(TBaseSiteForm)
    AddGadgetButton: TButton;
    MemoryGroup: TRadioGroup;
    CoverGroup: TRadioGroup;
    PremiumGroup: TRadioGroup;
    QuotePanel: TPanel;
    FirstNameEdit: TDBEdit;
    LastNameEdit: TDBEdit;
    MobileEdit: TDBEdit;
    EmailEdit: TDBEdit;
    CardButton: TButton;
    DirectDebitButton: TButton;
    DiscountLabel: THTMLSpan;
    GadgetDetailsPanel: TPanel;
    ModelNameLabel: TDBLabel;
    MemoryLabel: TDBLabel;
    PremiumLabel: TDBLabel;
    PremiumKindLabel: TDBLabel;
    DamageExcessLabel: TDBLabel;
    TheftExcessLabel: TDBLabel;
    LossOptionLabel: TDBLabel;
    DateOfPurchase: TDBLabel;
    AddDatePurchased: TLabel;
    AddSerialNumber: TLabel;
    QuoteSaveLabel: TLabel;
    PolicyTable: TDBTableControl;
    MarketingOK: TDBCheckBox;
    NetPremiumLabel: TDBLabel;
    BasketSource: TDataSource;
    memorypanel: TPanel;
    SerialNumLabel: TLabel;
    SerialNumber: TDBLabel;
    TheftExcessCaption: TLabel;
    procedure AddDatePurchasedClick(Sender: TObject);
    procedure AddGadgetButtonClick(Sender: TObject);
    procedure AddSerialNumberClick(Sender: TObject);
    procedure CardButtonClick(Sender: TObject);
    procedure CoverGroupChange(Sender: TObject);
    procedure PremiumGroupChange(Sender: TObject);
    procedure EmailEditExit(Sender: TObject);
    procedure FirstNameEditExit(Sender: TObject);
    procedure DirectDebitButtonClick(Sender: TObject);
    procedure LastNameEditExit(Sender: TObject);
    procedure MemoryGroupChange(Sender: TObject);
    procedure MobileEditExit(Sender: TObject);
    procedure PolicyTableClickCell(Sender: TObject; ACol, ARow: Integer);
    procedure PolicyTableGetCellChildren(Sender: TObject; ACol, ARow: Integer; AField: TField; AValue: string; AElement:
      TJSHTMLElementRecord);
    procedure QuoteSaveLabelClick(Sender: TObject);
  private
    FSelectForm: TSelectForm;
    FLossOptionChanged: Boolean;
    FPaymentSelected: TPaymentProvider;
    { Private declarations }
    [async]
    procedure LoadAllOptions; async;
    procedure LoadMemOptions(APremium: TPremium);
    procedure LoadCoverOptions(APremium: TPremium);
    procedure LoadPremiumOptions(APremium: TPremium);
    procedure LoadImage(APremium: TPremium);
    procedure OnPolicyUpdate(Sender: TObject);
    procedure UpdateSerialNumberLabel;
    procedure UpdateDateOfPurchase;
    [async]
    procedure AddDatePurchasedProc; async;
    [async]
    procedure AddPolicy(const SelectedPremium: Integer); async;
    [async]
    procedure AddSerialNumberProc; async;
    [async]
    procedure ShowSelectForm(const FirstDisplay: Boolean = False); async;
    [async]
    procedure SaveForLater; async;
    procedure DeletePolicy(const APolicyId: Integer);
    procedure AddRowActions(const ARecordId: Integer; AParentElement: TJSHTMLElement);
  protected
    function IsValid(const AElementId: string; const HighlightControls: Boolean): Boolean; override;
    procedure AfterPolicyChange(Sender: TObject);
    procedure ReadyToProgress(const CanProgress: Boolean); override;
  public
    { Public declarations }
    [async]
    procedure InitForm; async; override;
    procedure AfterLoaded; override;
    procedure ShowAppTitle;
  protected procedure LoadDFMValues; override; end;

var
  GadgetForm: TGadgetForm;

implementation

{$R *.dfm}

uses
  System.DateUtils,
  MainDataModule,
  Gadget.Web.Utils,
  smx.webcore.types,
  SMX.XDataSvc.Utils,
  XData.Dataset.Utils,
  Gadget.Web.Consts,
  SMX.Web.Utils,
  SMX.CRM.Utils,
  SMX.Web.Document.Utils,
  SMX.Web.Layout.Utils,
  Gadget.Web.Data.Utils;

procedure TGadgetForm.AddDatePurchasedClick(Sender: TObject);
begin
  AddDatePurchasedProc;
end;

procedure TGadgetForm.AddDatePurchasedProc;
var
  lValue, lText: string;
  lRetval: TModalResult;
  lData: TArray<string>;
  lDate: TDate;
begin
  if MainData.PolicyDataDateOfPurchase.Value = 0 then
    lValue := ''
  else
    lValue := FormatDateTime('yyyy-mm-dd', MainData.PolicyDataDateOfPurchase.Value);

  InputQueryDlg.InputValue := lValue;
  InputQueryDlg.InputType := itDate;

  lRetval := await(TModalResult, InputQueryDlg.ShowDialog('When did you buy your device?',
    WEBLib.Dialogs.mtConfirmation, [mbOK, mbCancel]));

  if lRetval = mrOk then
  begin
    lValue := InputQueryDlg.InputValue;
    lData := lValue.Split(['-']);
    lDate := EncodeDate(lData[0].ToInteger, lData[1].ToInteger, lData[2].ToInteger);
    if MonthsBetween(Now, lDate) > Device_Age_Validity then
    begin
      lText := MainData.LookUpValue(DEVICE_TOO_OLD);
      if lText = '' then
        lText := 'Your device is too old to be covered by this policy';
      DisplayMessage(lText, TMsgDlgType.mtWarning);
      { TODO : Now what???? }
    end;

    TXDataDSUtils.DatasetEdit(MainData.PolicyData);
    MainData.PolicyDataDateOfPurchase.Value := lDate;
    UpdateDateOfPurchase;
  end;
end;

procedure TGadgetForm.AddGadgetButtonClick(Sender: TObject);
begin
  ShowSelectForm;
end;

procedure TGadgetForm.AddPolicy(const SelectedPremium: Integer);
begin
  await(MainData.LoadPolicy(SelectedPremium));
end;

procedure TGadgetForm.AddRowActions(const ARecordId: Integer; AParentElement: TJSHTMLElement);

  procedure DeleteClick(Sender: TObject);
  begin
    DeletePolicy(ARecordId);
  end;

var
  Span: THTMLSpan;
begin
  Span := TLayoutUtils.RowActionSpan(AParentElement, 'fad fa-delete-left fa-fw fa-lg', 'Delete');
  Span.OnClick := @DeleteClick;
end;

procedure TGadgetForm.AddSerialNumberClick(Sender: TObject);
begin
  AddSerialNumberProc;
end;

procedure TGadgetForm.AddSerialNumberProc;
var
  lRetval: TModalResult;
  lValue, lText: string;
  I: Integer;
  AInputType: WEBLib.Controls.TInputType;
  lGadgetType: TGadgetType;
begin
  if TXDataDSUtils.IsOpenAndNotEmpty(MainData.PolicyData) then
    lValue := MainData.PolicyDataSerialNumber.Value
  else
    lValue := '';

  lGadgetType := TGadgetUtils.StrToGadgetType(MainData.PolicyDataGadgetType.Value);
  InputQueryDlg.InputType := itText;
  InputQueryDlg.InputValue := lValue;

  if lGadgetType = TGadgetType.MobilePhone then
    lText := 'Please enter your device''s IMEI'
  else
    lText := 'Please enter your device''s serial number';

  I := 0;
  repeat
    lRetval := await(TModalResult, InputQueryDlg.ShowDialog(lText, WEBLib.Dialogs.mtConfirmation, [mbOK, mbCancel]));

    if lRetval = mrOk then
    begin
      lValue := InputQueryDlg.InputValue;
      lValue := lValue.Replace(' ', '', [rfReplaceAll]);
      if (lGadgetType = TGadgetType.MobilePhone) and (not TGadgetUtils.ValidateIMEI(lValue)) then
      begin
        lRetval := mrRetry;
        if I = 0 then
        begin
          lText := 'The IMEI you entered is not valid. Please check your IMEI and try again';
          Inc(I);
        end;
      end;
    end;
  until lRetval <> mrRetry;

  if lRetval = mrOk then
  begin
    lValue := InputQueryDlg.InputValue;
    TXDataDSUtils.DatasetEdit(MainData.PolicyData);
    MainData.PolicyDataSerialNumber.Value := lValue.ToUpper;
    MainData.PolicyData.Post;
    UpdateSerialNumberLabel;
  end;
end;

{ TGadgetForm }

procedure TGadgetForm.AfterLoaded;
begin
  inherited;

  if Assigned(MainData.LastOutcome) and (MainData.LastOutcome.Status = TBasicResponseStatus.rsError) then
  begin
    DisplayMessage(MainData.LastOutcome.Description, mtWarning);
  end;

  if (MainData.PolicyCount = 0) or MainData.AutoShowGadgetSelect then
  begin
    MainData.AutoShowGadgetSelect := False;
    ShowSelectForm(True);
  end;
end;

procedure TGadgetForm.AfterPolicyChange(Sender: TObject);
var
  lCount: Integer;
  lDiscount: Double;
begin
  inherited;
  LoadAllOptions;
  PolicyTable.HideColumn(0);
  lCount := MainData.PolicyCount;
  CardButton.Enabled := lCount > 0;
  DirectDebitButton.Enabled := lCount > 0;

  if MainData.BasketDataLossCover.Value then
    TheftExcessCaption.Caption := 'Theft and Loss Excess'
  else
    TheftExcessCaption.Caption := 'Theft Excess';

  lDiscount := MainData.BasketDataTotalDiscountRate.Value;

  if lCount = 0 then
  begin
    AddGadgetButton.Caption := 'Insure my gadget (or add 2 for a 10% discount)';
    if lDiscount = 0 then
      DiscountLabel.HTML.Text := 'Add 2 gadgets for a 10&percnt; discount'
    else
      DiscountLabel.HTML.Text := Format('You have a %s&percnt; discount, add 2 gadgets for a 10&percnt; discount',
        [FormatFloat('#0', lDiscount)]);
    if MainData.ProcessingState = TProcessingState.psDeletingPolicy then
    begin
      MainData.ProcessingState := TProcessingState.psStandard;
      ShowSelectForm(False);
    end;
  end
  else if lCount = 1 then
  begin
    AddGadgetButton.Caption := 'Insure more gadgets for a 10% discount';
    if lDiscount = 0 then
      DiscountLabel.HTML.Text := 'Add another gadget for a 10&percnt; discount'
    else
      DiscountLabel.HTML.Text := Format('You have a %s&percnt; discount, add another gadget for a 10&percnt; discount',
        [FormatFloat('#0', lDiscount)]);
  end
  else if lCount = 2 then
  begin
    AddGadgetButton.Caption := 'Insure another gadget (or add 2 more for a 20% discount)';
    DiscountLabel.HTML.Text := Format('You have a %s&percnt; discount, add 2 more gadgets for a 20&percnt; discount',
      [FormatFloat('#0', lDiscount)]);
  end
  else if lCount = 3 then
  begin
    AddGadgetButton.Caption := 'Insure more gadgets for a 20% discount';
    DiscountLabel.HTML.Text := Format('You have a %s&percnt; discount, add another gadget for a 20&percnt; discount',
      [FormatFloat('#0', lDiscount)]);
  end
  else
  begin
    AddGadgetButton.Caption := 'Add another gadget';
    DiscountLabel.HTML.Text := Format('You have a %s&percnt; discount!', [FormatFloat('#0', lDiscount)]);
  end;
end;

procedure TGadgetForm.CardButtonClick(Sender: TObject);
var
  lMarketingOK: Boolean;
begin
  FPaymentSelected := TPaymentProvider.Stripe;
  lMarketingOK := MarketingOK.Checked;
  TXDataDSUtils.DatasetEdit(MainData.CustomerData);
  MainData.CustomerDataMarketingOK.Value := lMarketingOK;
  MainData.SwitchToPayment;
end;

procedure TGadgetForm.CoverGroupChange(Sender: TObject);
begin

  if (MainData.PolicyGadget in Gadget_Types_Loss_Excluded) and (CoverGroup.ItemIndex = 1) then
  begin
    CoverGroup.ItemIndex := 0;
    Exit;
  end;

  FLossOptionChanged := True;
  MainData.ChangeLossOption(CoverGroup.ItemIndex = 1);
end;

procedure TGadgetForm.DeletePolicy(const APolicyId: Integer);
begin
  MainData.DeletePolicy(APolicyId);
end;

procedure TGadgetForm.DirectDebitButtonClick(Sender: TObject);
var
  lMarketingOK: Boolean;
begin
  lMarketingOK := MarketingOK.Checked;
  TXDataDSUtils.DatasetEdit(MainData.CustomerData);
  MainData.CustomerDataMarketingOK.Value := lMarketingOK;
  FPaymentSelected := TPaymentProvider.DirectDebit;
  MainData.SwitchToPayment;
end;

procedure TGadgetForm.EmailEditExit(Sender: TObject);
begin
  IsValid(EmailEdit.ElementID, False);
end;

procedure TGadgetForm.FirstNameEditExit(Sender: TObject);
begin
  IsValid(FirstNameEdit.ElementID, False);
end;

procedure TGadgetForm.InitForm;
begin
  inherited;
  MainData.OnReadyToProgess := ReadyToProgress;
  MainData.AfterPolicyUpdate := AfterPolicyChange;
  DiscountLabel.HTML.Clear;
  LoadAllOptions;
  PolicyTable.HideColumn(0);
end;

function TGadgetForm.IsValid(const AElementId: string; const HighlightControls: Boolean): Boolean;
var
  ControlValid: Boolean;
begin
  Result := inherited IsValid(AElementId, HighlightControls);

  ControlValid := MainData.CustomerDataForename.Value <> '';
  Result := Result and ControlValid;
  HighlightControl(FirstNameEdit.ElementID, AElementId, HighlightControls, ControlValid);

  ControlValid := MainData.CustomerDataSurname.Value <> '';
  Result := Result and ControlValid;
  HighlightControl(LastNameEdit.ElementID, AElementId, HighlightControls, ControlValid);

  ControlValid := TUtils.IsPhoneNumberValid(MobileEdit.Text);
  Result := Result and ControlValid;
  HighlightControl(MobileEdit.ElementID, AElementId, HighlightControls, ControlValid);

  ControlValid := TCRMUtils.IsEmailValid(MainData.CustomerDataEmail.Value);
  Result := Result and ControlValid;
  HighlightControl(EmailEdit.ElementID, AElementId, HighlightControls, ControlValid);
end;

procedure TGadgetForm.LastNameEditExit(Sender: TObject);
begin
  IsValid(LastNameEdit.ElementID, False);
end;

procedure TGadgetForm.LoadAllOptions;
var
  lPremium: TPremium;
begin
  if MainData.PolicyCount = 0 then
  begin
    SerialNumLabel.Caption := 'IMEI / Serial No.';
    Exit;
  end;

  if MainData.PolicyGadget = TGadgetType.MobilePhone then
  begin
    SerialNumLabel.Caption := 'IMEI';
    TDocUtils.showElementWithClass('imei-help');
  end
  else
  begin
    SerialNumLabel.Caption := 'Serial No.';
    TDocUtils.hideElementWithClass('imei-help');
  end;

  lPremium := await(MainData.FindPremium(MainData.PolicyDataPremiumId.Value));
  LoadMemOptions(lPremium);
  LoadCoverOptions(lPremium);
  LoadPremiumOptions(lPremium);
  LoadImage(lPremium);
  if memorypanel.ElementClassName = smx_hide_class then
    CoverGroup.SetFocus
  else
    MemoryGroup.SetFocus;

  UpdateSerialNumberLabel;
  UpdateDateOfPurchase;
end;

procedure TGadgetForm.LoadCoverOptions(APremium: TPremium);
const
  _template = '<span class="coverCaption">%s</span>';
  _basic = 'Accidental Damage and Theft';
  _enhanced = 'Accidental Damage, Theft <b>and Loss</b>';
  _unavailable = 'Loss cover not available for this gadget type';
  _disabled = ' class="disabled"';
var
  idx, itm, itm2: Integer;
begin
  CoverGroup.BeginUpdate;
  try
    CoverGroup.Items.Clear;

    if not Assigned(APremium) then
    begin
      CoverGroup.Items.Add(Format(_template, ['']));
      CoverGroup.Items.Add(Format(_template, ['']));
      TJSHTMLElement(CoverGroup.Controls[0].ElementHandle.firstChild).setAttribute('class', 'disabled');
      TJSHTMLElement(CoverGroup.Controls[1].ElementHandle.firstChild).setAttribute('class', 'disabled');
      Exit;
    end;

    CoverGroup.Items.Add(Format(_template, [_basic]));

    if not (MainData.PolicyGadget in Gadget_Types_Loss_Excluded) then
    begin
      CoverGroup.Items.Add(Format(_template, [_enhanced]));
      if MainData.PolicyDataLossCover.Value then
        idx := 1
      else
        idx := 0;
    end
    else
    begin
      CoverGroup.Items.Add(Format(_template, [_unavailable]));
      TJSHTMLElement(CoverGroup.Controls[1].ElementHandle.firstChild).setAttribute('class', 'disabled');
      idx := 0;
    end;

    CoverGroup.ItemIndex := idx;
  finally
    CoverGroup.EndUpdate;
  end;
end;

procedure TGadgetForm.LoadImage(APremium: TPremium);
begin
  if Assigned(APremium) then
    TDocUtils.writeImageSrc('product-image', APremium.Image)
  else
    TDocUtils.writeImageSrc('product-image', 'images/defaultproduct.png')
end;

procedure TGadgetForm.LoadMemOptions(APremium: TPremium);
const
  _template = '<span class="memoryAmount">%s</span><span class="memoryCaption">Storage</span>';
var
  lMem: TArray<string>;
  lItm: TArray<string>;
  I, idx, itm, mLen: Integer;
  lSelected: Boolean;
  S: string;
begin
  MemoryGroup.BeginUpdate;
  try
    MemoryGroup.Items.Clear;

    if not Assigned(APremium) then
    begin
      for I := 0 to 3 do
        MemoryGroup.Items.Add('');
      Exit;
    end;

    S := APremium.MemoryOptions;
    lMem := APremium.MemoryOptions.Split([';']);
    idx := -1;

    mLen := Length(lMem);
    if mLen = 1 then
    begin
      lItm := lMem[0].Split(['=']);
      itm := MemoryGroup.Items.Add(Format(_template, [lItm[0]]));
      idx := itm;
      memorypanel.ElementClassName := smx_hide_class;
    end
    else
    begin
      for I := 0 to mLen - 1 do
      begin
        lItm := lMem[I].Split(['=']);
        itm := MemoryGroup.Items.Add(Format(_template, [lItm[0]]));
        if lItm[0] = MainData.PolicyDataInstalledMemory.Value then
          idx := itm;
      end;
      memorypanel.ElementClassName := smx_show_class;
    end;
  finally
    MemoryGroup.ItemIndex := idx;
    MemoryGroup.EndUpdate;
    MemoryGroup.Visible := MemoryGroup.Items.Count > 0;
  end;
end;

procedure TGadgetForm.LoadPremiumOptions(APremium: TPremium);
const
  _template = '<span class="premiumCaption">%s</span><span class="premiumAmount">&pound%s</span>';
var
  lPremiumKind: TPremiumKind;
  lMonthly, lAnnual: Double;
begin
  lPremiumKind := TGadgetUtils.StrToPremiumKind(MainData.PolicyDataPremiumPeriod.Value);
  PremiumGroup.BeginUpdate;
  try
    PremiumGroup.Items.Clear;
    if not Assigned(APremium) then
    begin
      PremiumGroup.Items.Add(Format(_template, ['Monthly Cover', '']));
      PremiumGroup.Items.Add(Format(_template, ['Annual Cover', '']));
      Exit;
    end;

    // PremiumGroup.Items.Add(Format(_template, ['Monthly Cover', FormatFloat('#,##0.00', APremium.Premium)]));
    // PremiumGroup.Items.Add(Format(_template, ['Annual Cover', FormatFloat('#,##0.00', APremium.AnnualPremium)]));

    lMonthly := TDataUtils.CalcDisplayedPremium(TPremiumKind.MonthlyCover, APremium,
      MainData.PolicyDataLossCover.Value, MainData.PolicyDataDiscountPercent.Value);
    lAnnual := TDataUtils.CalcDisplayedPremium(TPremiumKind.AnnualCover, APremium,
      MainData.PolicyDataLossCover.Value, MainData.PolicyDataDiscountPercent.Value);

    PremiumGroup.Items.Add(Format(_template, ['Monthly Cover', FormatFloat('#,##0.00', lMonthly)]));
    PremiumGroup.Items.Add(Format(_template, ['Annual Cover', FormatFloat('#,##0.00', lAnnual)]));

    PremiumGroup.ItemIndex := Integer(lPremiumKind);
  finally
    PremiumGroup.EndUpdate;
  end;
end;

procedure TGadgetForm.MemoryGroupChange(Sender: TObject);
begin
  MainData.ChangeMemoryOption(MemoryGroup.ItemIndex);
end;

procedure TGadgetForm.MobileEditExit(Sender: TObject);
begin
  IsValid(MobileEdit.ElementID, False);
end;

procedure TGadgetForm.OnPolicyUpdate(Sender: TObject);
begin
  //
end;

procedure TGadgetForm.PolicyTableClickCell(Sender: TObject; ACol, ARow: Integer);
var
  PolicyId: Integer;
begin
  PolicyId := PolicyTable.Cells[0, ARow].ToInteger;
  if MainData.LocatePolicy(PolicyId) then
    AfterPolicyChange(Self);
end;

procedure TGadgetForm.PolicyTableGetCellChildren(Sender: TObject; ACol, ARow: Integer; AField: TField; AValue: string;
  AElement: TJSHTMLElementRecord);
begin
  if ARow = 0 then
    Exit;

  if PolicyTable.Columns[ACol].Title = '' then
  begin
    AddRowActions(MainData.PolicyDataId.Value, AElement.Element);
  end;
end;

procedure TGadgetForm.PremiumGroupChange(Sender: TObject);
begin
  MainData.ChangePremiumKind(TPremiumKind(PremiumGroup.ItemIndex));
end;

procedure TGadgetForm.QuoteSaveLabelClick(Sender: TObject);
begin
  SaveForLater;
end;

procedure TGadgetForm.ReadyToProgress(const CanProgress: Boolean);
begin
  inherited;
  UpdateDebugInfo;
  if MainData.ProcessingState = TProcessingState.psSaveForLater then
  begin
    MainData.ProcessingState := TProcessingState.psStandard;
    if MainData.LastOutcome.Status = TBasicResponseStatus.rsOK then
      DisplayMessage
        ('Your basket has been saved for later. You will receive an email which will have a link for you to continue')
    else
      DisplayMessage('Unfortunateley we have been unable to save your basket. Please try later', mtWarning);
    MainData.ProcessingState := TProcessingState.psStandard;
  end
  else if MainData.ProcessingState = TProcessingState.psSwitchToPayment then
  begin
    case FPaymentSelected of
      TPaymentProvider.Stripe:
        ChangeSitePage(TGadgetPage.gpCardPayment);
      TPaymentProvider.DirectDebit:
        ChangeSitePage(TGadgetPage.gpDirectDebit);
    end;
    MainData.ProcessingState := TProcessingState.psStandard;
    Exit;
  end
  else if MainData.ProcessingState = TProcessingState.psDeletingPolicy then
  begin
    // see AfterPolicyChange
  end;

  AfterPolicyChange(Self);
end;

procedure TGadgetForm.SaveForLater;
var
  lMarketingOK: Boolean;
begin
  if not MainData.ValidForQuoteSave then
  begin
    DisplayMessage('You need to enter a valid email address to save a quote', mtWarning);
    EmailEdit.SetFocus;
    Exit;
  end;

  lMarketingOK := MarketingOK.Checked;
  MainData.CustomerDataMarketingOK.Value := lMarketingOK;

  await(MainData.SaveForLater);
end;

procedure TGadgetForm.ShowAppTitle;
begin
  Caption := Application_Title;
end;

procedure TGadgetForm.ShowSelectForm(const FirstDisplay: Boolean);
var
  lRetval: TModalResult;
begin
  if Assigned(FSelectForm) then
  begin
    FSelectForm.Free;
    FSelectForm := nil;
  end;

  FSelectForm := TSelectForm.Create(Self);
  try
    FSelectForm.Popup := True;
    await(TForm, FSelectForm.Load());
    FSelectForm.PopupOpacity := 0.5;
    FSelectForm.ElementClassName := POPUP_FORM_CLASS;
    FSelectForm.InitialDisplay := FirstDisplay;
    await(FSelectForm.InitForm);
    lRetval := await(TModalResult, FSelectForm.Execute);
    if lRetval = mrOk then
      AddPolicy(FSelectForm.SelectedPremium);
  finally
    FSelectForm.Free;
    FSelectForm := nil;
  end;
end;

procedure TGadgetForm.UpdateDateOfPurchase;
begin
  if MainData.PolicyDataDateOfPurchase.Value = 0 then
    AddDatePurchased.Caption := 'ADD'
  else
    AddDatePurchased.Caption := 'CHANGE';
end;

procedure TGadgetForm.UpdateSerialNumberLabel;
begin
  if MainData.PolicyDataSerialNumber.Value <> '' then
    AddSerialNumber.Caption := 'CHANGE'
  else
    AddSerialNumber.Caption := 'ADD';
end;

procedure TGadgetForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  QuoteSaveLabel := TLabel.Create('QuoteSaveLabel');
  AddGadgetButton := TButton.Create('AddGadgetButton');
  CoverGroup := TRadioGroup.Create('CoverGroup');
  PremiumGroup := TRadioGroup.Create('PremiumGroup');
  QuotePanel := TPanel.Create('QuotePanel');
  NetPremiumLabel := TDBLabel.Create('NetPremiumLabel');
  FirstNameEdit := TDBEdit.Create('FirstNameEdit');
  LastNameEdit := TDBEdit.Create('LastNameEdit');
  MobileEdit := TDBEdit.Create('MobileEdit');
  EmailEdit := TDBEdit.Create('EmailEdit');
  CardButton := TButton.Create('CardButton');
  DirectDebitButton := TButton.Create('DirectDebitButton');
  DiscountLabel := THTMLSpan.Create('DiscountLabel');
  PolicyTable := TDBTableControl.Create('PolicyTable');
  MarketingOK := TDBCheckBox.Create('MarketingOK');
  GadgetDetailsPanel := TPanel.Create('GadgetDetailsPanel');
  ModelNameLabel := TDBLabel.Create('ModelLabel');
  MemoryLabel := TDBLabel.Create('MemoryLabel');
  PremiumLabel := TDBLabel.Create('PremiumLabel');
  PremiumKindLabel := TDBLabel.Create('PremiumKindLabel');
  DamageExcessLabel := TDBLabel.Create('DamageExcessLabel');
  TheftExcessLabel := TDBLabel.Create('TheftExcessLabel');
  LossOptionLabel := TDBLabel.Create('LossOptionLabel');
  DateOfPurchase := TDBLabel.Create('date-purchased');
  AddDatePurchased := TLabel.Create('add-date-purchased');
  AddSerialNumber := TLabel.Create('AddSerialNumber');
  SerialNumLabel := TLabel.Create('SerialNumLabel');
  SerialNumber := TDBLabel.Create('serial-number');
  TheftExcessCaption := TLabel.Create('theft-excess-caption');
  memorypanel := TPanel.Create('memory-panel');
  MemoryGroup := TRadioGroup.Create('MemoryGroup');
  BasketSource := TDataSource.Create(Self);

  QuoteSaveLabel.BeforeLoadDFMValues;
  InputQueryDlg.BeforeLoadDFMValues;
  AddGadgetButton.BeforeLoadDFMValues;
  CoverGroup.BeforeLoadDFMValues;
  PremiumGroup.BeforeLoadDFMValues;
  QuotePanel.BeforeLoadDFMValues;
  NetPremiumLabel.BeforeLoadDFMValues;
  FirstNameEdit.BeforeLoadDFMValues;
  LastNameEdit.BeforeLoadDFMValues;
  MobileEdit.BeforeLoadDFMValues;
  EmailEdit.BeforeLoadDFMValues;
  CardButton.BeforeLoadDFMValues;
  DirectDebitButton.BeforeLoadDFMValues;
  DiscountLabel.BeforeLoadDFMValues;
  PolicyTable.BeforeLoadDFMValues;
  MarketingOK.BeforeLoadDFMValues;
  GadgetDetailsPanel.BeforeLoadDFMValues;
  ModelNameLabel.BeforeLoadDFMValues;
  MemoryLabel.BeforeLoadDFMValues;
  PremiumLabel.BeforeLoadDFMValues;
  PremiumKindLabel.BeforeLoadDFMValues;
  DamageExcessLabel.BeforeLoadDFMValues;
  TheftExcessLabel.BeforeLoadDFMValues;
  LossOptionLabel.BeforeLoadDFMValues;
  DateOfPurchase.BeforeLoadDFMValues;
  AddDatePurchased.BeforeLoadDFMValues;
  AddSerialNumber.BeforeLoadDFMValues;
  SerialNumLabel.BeforeLoadDFMValues;
  SerialNumber.BeforeLoadDFMValues;
  TheftExcessCaption.BeforeLoadDFMValues;
  memorypanel.BeforeLoadDFMValues;
  MemoryGroup.BeforeLoadDFMValues;
  PolicySource.BeforeLoadDFMValues;
  CustomerSource.BeforeLoadDFMValues;
  BasketSource.BeforeLoadDFMValues;
  try
    Width := 969;
    Height := 762;
    QuoteSaveLabel.SetParentComponent(Self);
    QuoteSaveLabel.Name := 'QuoteSaveLabel';
    QuoteSaveLabel.Left := 752;
    QuoteSaveLabel.Top := 24;
    QuoteSaveLabel.Width := 88;
    QuoteSaveLabel.Height := 18;
    QuoteSaveLabel.Cursor := crHandPoint;
    QuoteSaveLabel.Caption := 'Save for later';
    QuoteSaveLabel.ElementFont := efCSS;
    QuoteSaveLabel.HeightStyle := ssAuto;
    QuoteSaveLabel.HeightPercent := 100.000000000000000000;
    QuoteSaveLabel.WidthPercent := 100.000000000000000000;
    SetEvent(QuoteSaveLabel, Self, 'OnClick', 'QuoteSaveLabelClick');
    InputQueryDlg.Left := 34;
    AddGadgetButton.SetParentComponent(Self);
    AddGadgetButton.Name := 'AddGadgetButton';
    AddGadgetButton.Left := 64;
    AddGadgetButton.Top := 512;
    AddGadgetButton.Width := 281;
    AddGadgetButton.Height := 25;
    AddGadgetButton.Caption := 'Add Another Gadget and Save 10%';
    AddGadgetButton.ChildOrder := 5;
    AddGadgetButton.ElementClassName := 'btn btn-primary';
    AddGadgetButton.ElementFont := efCSS;
    AddGadgetButton.ElementPosition := epIgnore;
    AddGadgetButton.HeightStyle := ssAuto;
    AddGadgetButton.HeightPercent := 100.000000000000000000;
    AddGadgetButton.TabOrder := 4;
    AddGadgetButton.WidthStyle := ssAuto;
    AddGadgetButton.WidthPercent := 100.000000000000000000;
    SetEvent(AddGadgetButton, Self, 'OnClick', 'AddGadgetButtonClick');
    CoverGroup.SetParentComponent(Self);
    CoverGroup.Name := 'CoverGroup';
    CoverGroup.Left := 56;
    CoverGroup.Top := 167;
    CoverGroup.Width := 160;
    CoverGroup.Height := 120;
    CoverGroup.ElementClassName := 'custom-control custom-radio';
    CoverGroup.HeightStyle := ssAuto;
    CoverGroup.WidthStyle := ssAuto;
    CoverGroup.Caption := '';
    CoverGroup.ChildOrder := 6;
    CoverGroup.Columns := 1;
    CoverGroup.ElementButtonClassName := 'custom-control-input';
    CoverGroup.ElementGroupClassName := 'modal-content';
    CoverGroup.ElementLabelClassName := 'custom-control-label';
    CoverGroup.ElementLegendClassName := 'h6';
    CoverGroup.ElementFont := efCSS;
    CoverGroup.ElementPosition := epIgnore;
    CoverGroup.ItemIndex := 0;
    CoverGroup.Role := '';
    CoverGroup.TabOrder := 2;
    SetEvent(CoverGroup, Self, 'OnChange', 'CoverGroupChange');
    PremiumGroup.SetParentComponent(Self);
    PremiumGroup.Name := 'PremiumGroup';
    PremiumGroup.Left := 56;
    PremiumGroup.Top := 293;
    PremiumGroup.Width := 160;
    PremiumGroup.Height := 120;
    PremiumGroup.ElementClassName := 'custom-control custom-radio';
    PremiumGroup.HeightStyle := ssAuto;
    PremiumGroup.WidthStyle := ssAuto;
    PremiumGroup.Caption := '';
    PremiumGroup.ChildOrder := 6;
    PremiumGroup.Columns := 1;
    PremiumGroup.ElementButtonClassName := 'custom-control-input';
    PremiumGroup.ElementGroupClassName := 'modal-content';
    PremiumGroup.ElementLabelClassName := 'custom-control-label';
    PremiumGroup.ElementLegendClassName := 'h6';
    PremiumGroup.ElementFont := efCSS;
    PremiumGroup.ElementPosition := epIgnore;
    PremiumGroup.ItemIndex := 0;
    PremiumGroup.Role := '';
    PremiumGroup.TabOrder := 3;
    SetEvent(PremiumGroup, Self, 'OnChange', 'PremiumGroupChange');
    QuotePanel.SetParentComponent(Self);
    QuotePanel.Name := 'QuotePanel';
    QuotePanel.Left := 568;
    QuotePanel.Top := 63;
    QuotePanel.Width := 353;
    QuotePanel.Height := 626;
    QuotePanel.ElementClassName := 'card';
    QuotePanel.HeightStyle := ssAuto;
    QuotePanel.WidthStyle := ssAuto;
    QuotePanel.BorderStyle := bsNone;
    QuotePanel.ChildOrder := 6;
    QuotePanel.Color := clWhite;
    QuotePanel.ElementBodyClassName := 'card-body';
    QuotePanel.ElementFont := efCSS;
    QuotePanel.ElementPosition := epIgnore;
    QuotePanel.TabOrder := 4;
    NetPremiumLabel.SetParentComponent(QuotePanel);
    NetPremiumLabel.Name := 'NetPremiumLabel';
    NetPremiumLabel.Left := 24;
    NetPremiumLabel.Top := 272;
    NetPremiumLabel.Width := 113;
    NetPremiumLabel.Height := 18;
    NetPremiumLabel.Caption := 'NetPremiumLabel';
    NetPremiumLabel.ElementFont := efCSS;
    NetPremiumLabel.ElementPosition := epIgnore;
    NetPremiumLabel.HeightStyle := ssAuto;
    NetPremiumLabel.HeightPercent := 100.000000000000000000;
    NetPremiumLabel.WidthStyle := ssAuto;
    NetPremiumLabel.WidthPercent := 100.000000000000000000;
    NetPremiumLabel.DataField := 'NetPremium';
    NetPremiumLabel.DataSource := BasketSource;
    FirstNameEdit.SetParentComponent(QuotePanel);
    FirstNameEdit.Name := 'FirstNameEdit';
    FirstNameEdit.Left := 8;
    FirstNameEdit.Top := 320;
    FirstNameEdit.Width := 121;
    FirstNameEdit.Height := 22;
    FirstNameEdit.ChildOrder := 11;
    FirstNameEdit.ElementClassName := 'form-control';
    FirstNameEdit.ElementFont := efCSS;
    FirstNameEdit.ElementPosition := epIgnore;
    FirstNameEdit.HeightStyle := ssAuto;
    FirstNameEdit.HeightPercent := 100.000000000000000000;
    FirstNameEdit.MaxLength := 25;
    FirstNameEdit.TabOrder := 5;
    FirstNameEdit.Text := 'FirstNameEdit';
    FirstNameEdit.WidthStyle := ssAuto;
    FirstNameEdit.WidthPercent := 100.000000000000000000;
    SetEvent(FirstNameEdit, Self, 'OnExit', 'FirstNameEditExit');
    FirstNameEdit.DataField := 'Forename';
    FirstNameEdit.DataSource := CustomerSource;
    LastNameEdit.SetParentComponent(QuotePanel);
    LastNameEdit.Name := 'LastNameEdit';
    LastNameEdit.Left := 168;
    LastNameEdit.Top := 320;
    LastNameEdit.Width := 121;
    LastNameEdit.Height := 22;
    LastNameEdit.ChildOrder := 1;
    LastNameEdit.ElementClassName := 'form-control';
    LastNameEdit.ElementFont := efCSS;
    LastNameEdit.ElementPosition := epIgnore;
    LastNameEdit.HeightStyle := ssAuto;
    LastNameEdit.HeightPercent := 100.000000000000000000;
    LastNameEdit.MaxLength := 30;
    LastNameEdit.TabOrder := 6;
    LastNameEdit.Text := 'LastNameEdit';
    LastNameEdit.WidthStyle := ssAuto;
    LastNameEdit.WidthPercent := 100.000000000000000000;
    SetEvent(LastNameEdit, Self, 'OnExit', 'LastNameEditExit');
    LastNameEdit.DataField := 'Surname';
    LastNameEdit.DataSource := CustomerSource;
    MobileEdit.SetParentComponent(QuotePanel);
    MobileEdit.Name := 'MobileEdit';
    MobileEdit.Left := 8;
    MobileEdit.Top := 360;
    MobileEdit.Width := 121;
    MobileEdit.Height := 22;
    MobileEdit.ChildOrder := 2;
    MobileEdit.ElementClassName := 'form-control';
    MobileEdit.ElementFont := efCSS;
    MobileEdit.ElementPosition := epIgnore;
    MobileEdit.HeightStyle := ssAuto;
    MobileEdit.HeightPercent := 100.000000000000000000;
    MobileEdit.MaxLength := 25;
    MobileEdit.TabOrder := 7;
    MobileEdit.Text := 'MobileEdit';
    MobileEdit.WidthStyle := ssAuto;
    MobileEdit.WidthPercent := 100.000000000000000000;
    SetEvent(MobileEdit, Self, 'OnExit', 'MobileEditExit');
    MobileEdit.DataField := 'Mobile';
    MobileEdit.DataSource := CustomerSource;
    EmailEdit.SetParentComponent(QuotePanel);
    EmailEdit.Name := 'EmailEdit';
    EmailEdit.Left := 168;
    EmailEdit.Top := 360;
    EmailEdit.Width := 121;
    EmailEdit.Height := 22;
    EmailEdit.ChildOrder := 3;
    EmailEdit.ElementClassName := 'form-control';
    EmailEdit.ElementFont := efCSS;
    EmailEdit.ElementPosition := epIgnore;
    EmailEdit.HeightStyle := ssAuto;
    EmailEdit.HeightPercent := 100.000000000000000000;
    EmailEdit.MaxLength := 75;
    EmailEdit.TabOrder := 8;
    EmailEdit.Text := 'EmailEdit';
    EmailEdit.WidthStyle := ssAuto;
    EmailEdit.WidthPercent := 100.000000000000000000;
    SetEvent(EmailEdit, Self, 'OnExit', 'EmailEditExit');
    EmailEdit.DataField := 'Email';
    EmailEdit.DataSource := CustomerSource;
    CardButton.SetParentComponent(QuotePanel);
    CardButton.Name := 'CardButton';
    CardButton.Left := 11;
    CardButton.Top := 464;
    CardButton.Width := 281;
    CardButton.Height := 25;
    CardButton.Caption := 'Pay with Card';
    CardButton.ChildOrder := 4;
    CardButton.ElementClassName := 'btn btn-primary';
    CardButton.ElementFont := efCSS;
    CardButton.ElementPosition := epIgnore;
    CardButton.HeightStyle := ssAuto;
    CardButton.HeightPercent := 100.000000000000000000;
    CardButton.WidthStyle := ssAuto;
    CardButton.WidthPercent := 100.000000000000000000;
    SetEvent(CardButton, Self, 'OnClick', 'CardButtonClick');
    DirectDebitButton.SetParentComponent(QuotePanel);
    DirectDebitButton.Name := 'DirectDebitButton';
    DirectDebitButton.Left := 8;
    DirectDebitButton.Top := 495;
    DirectDebitButton.Width := 281;
    DirectDebitButton.Height := 25;
    DirectDebitButton.Caption := 'Pay with Direct Debit';
    DirectDebitButton.ChildOrder := 4;
    DirectDebitButton.ElementClassName := 'btn btn-primary';
    DirectDebitButton.ElementFont := efCSS;
    DirectDebitButton.ElementPosition := epIgnore;
    DirectDebitButton.HeightStyle := ssAuto;
    DirectDebitButton.HeightPercent := 100.000000000000000000;
    DirectDebitButton.TabOrder := 11;
    DirectDebitButton.WidthStyle := ssAuto;
    DirectDebitButton.WidthPercent := 100.000000000000000000;
    SetEvent(DirectDebitButton, Self, 'OnClick', 'DirectDebitButtonClick');
    DiscountLabel.SetParentComponent(QuotePanel);
    DiscountLabel.Name := 'DiscountLabel';
    DiscountLabel.Left := 168;
    DiscountLabel.Top := 272;
    DiscountLabel.Width := 100;
    DiscountLabel.Height := 24;
    DiscountLabel.HeightStyle := ssAuto;
    DiscountLabel.WidthStyle := ssAuto;
    DiscountLabel.ChildOrder := 26;
    DiscountLabel.ElementPosition := epIgnore;
    DiscountLabel.ElementFont := efCSS;
    DiscountLabel.HTML.BeginUpdate;
    try
      DiscountLabel.HTML.Clear;
      DiscountLabel.HTML.Add('DiscountLabel');
    finally
      DiscountLabel.HTML.EndUpdate;
    end;
    DiscountLabel.Role := '';
    PolicyTable.SetParentComponent(QuotePanel);
    PolicyTable.Name := 'PolicyTable';
    PolicyTable.Left := 24;
    PolicyTable.Top := 57;
    PolicyTable.Width := 300;
    PolicyTable.Height := 200;
    PolicyTable.HeightStyle := ssAuto;
    PolicyTable.WidthStyle := ssAuto;
    PolicyTable.BorderColor := clSilver;
    PolicyTable.ChildOrder := 11;
    PolicyTable.ElementFont := efCSS;
    PolicyTable.ElementPosition := epIgnore;
    PolicyTable.Footer.ButtonActiveElementClassName := 'btn btn-primary';
    PolicyTable.Footer.ButtonElementClassName := 'btn btn-light';
    PolicyTable.Footer.DropDownElementClassName := 'form-control';
    PolicyTable.Footer.InputElementClassName := 'form-control';
    PolicyTable.Footer.LinkActiveElementClassName := 'link-primary';
    PolicyTable.Footer.LinkElementClassName := 'link-secondary';
    PolicyTable.Footer.ListElementClassName := 'pagination';
    PolicyTable.Footer.ListItemElementClassName := 'page-item';
    PolicyTable.Footer.ListLinkElementClassName := 'page-link';
    PolicyTable.Header.ButtonActiveElementClassName := 'btn btn-primary';
    PolicyTable.Header.ButtonElementClassName := 'btn btn-light';
    PolicyTable.Header.DropDownElementClassName := 'form-control';
    PolicyTable.Header.InputElementClassName := 'form-control';
    PolicyTable.Header.LinkActiveElementClassName := 'link-primary';
    PolicyTable.Header.LinkElementClassName := 'link-secondary';
    PolicyTable.Header.ListElementClassName := 'pagination';
    PolicyTable.Header.ListItemElementClassName := 'page-item';
    PolicyTable.Header.ListLinkElementClassName := 'page-link';
    SetEvent(PolicyTable, Self, 'OnClickCell', 'PolicyTableClickCell');
    SetEvent(PolicyTable, Self, 'OnGetCellChildren', 'PolicyTableGetCellChildren');
    PolicyTable.Columns.Clear;
    with PolicyTable.Columns.Add do
    begin
      DataField := 'Id';
      Title := 'Id';
    end;
    with PolicyTable.Columns.Add do
    begin
      DataField := 'SummaryText';
      Title := 'Gadget';
    end;
    with PolicyTable.Columns.Add do
    begin
      DataField := 'NetPremium';
      Title := 'Premium';
    end;
    with PolicyTable.Columns.Add do
    begin
      DataField := 'LossCoverText';
      Title := 'Loss?';
    end;
    with PolicyTable.Columns.Add do
    begin
    end;
    PolicyTable.DataSource := PolicySource;
    MarketingOK.SetParentComponent(QuotePanel);
    MarketingOK.Name := 'MarketingOK';
    MarketingOK.Left := 168;
    MarketingOK.Top := 392;
    MarketingOK.Width := 113;
    MarketingOK.Height := 22;
    MarketingOK.ChildOrder := 12;
    MarketingOK.ElementClassName := 'custom-control custom-checkbox';
    MarketingOK.ElementButtonClassName := 'custom-control-input';
    MarketingOK.ElementLabelClassName := 'custom-control-label';
    MarketingOK.ElementFont := efCSS;
    MarketingOK.ElementPosition := epIgnore;
    MarketingOK.HeightStyle := ssAuto;
    MarketingOK.HeightPercent := 100.000000000000000000;
    MarketingOK.TabOrder := 9;
    MarketingOK.WidthStyle := ssAuto;
    MarketingOK.WidthPercent := 100.000000000000000000;
    MarketingOK.DataField := 'MarketingOK';
    MarketingOK.DataSource := CustomerSource;
    MarketingOK.ValueChecked := 'True';
    MarketingOK.ValueUnChecked := 'False';
    GadgetDetailsPanel.SetParentComponent(Self);
    GadgetDetailsPanel.Name := 'GadgetDetailsPanel';
    GadgetDetailsPanel.Left := 232;
    GadgetDetailsPanel.Top := 40;
    GadgetDetailsPanel.Width := 225;
    GadgetDetailsPanel.Height := 280;
    GadgetDetailsPanel.HeightStyle := ssAuto;
    GadgetDetailsPanel.WidthStyle := ssAuto;
    GadgetDetailsPanel.BorderStyle := bsNone;
    GadgetDetailsPanel.ChildOrder := 7;
    GadgetDetailsPanel.Color := clWhite;
    GadgetDetailsPanel.ElementFont := efCSS;
    GadgetDetailsPanel.ElementPosition := epIgnore;
    GadgetDetailsPanel.TabOrder := 5;
    ModelNameLabel.SetParentComponent(GadgetDetailsPanel);
    ModelNameLabel.Name := 'ModelNameLabel';
    ModelNameLabel.Left := 16;
    ModelNameLabel.Top := 32;
    ModelNameLabel.Width := 38;
    ModelNameLabel.Height := 18;
    ModelNameLabel.Caption := 'Model';
    ModelNameLabel.ElementFont := efCSS;
    ModelNameLabel.HeightStyle := ssAuto;
    ModelNameLabel.HeightPercent := 100.000000000000000000;
    ModelNameLabel.WidthPercent := 100.000000000000000000;
    ModelNameLabel.DataField := 'Model';
    ModelNameLabel.DataSource := PolicySource;
    MemoryLabel.SetParentComponent(GadgetDetailsPanel);
    MemoryLabel.Name := 'MemoryLabel';
    MemoryLabel.Left := 16;
    MemoryLabel.Top := 56;
    MemoryLabel.Width := 54;
    MemoryLabel.Height := 18;
    MemoryLabel.Caption := 'Memory';
    MemoryLabel.ElementFont := efCSS;
    MemoryLabel.HeightStyle := ssAuto;
    MemoryLabel.HeightPercent := 100.000000000000000000;
    MemoryLabel.WidthPercent := 100.000000000000000000;
    MemoryLabel.DataField := 'InstalledMemory';
    MemoryLabel.DataSource := PolicySource;
    PremiumLabel.SetParentComponent(GadgetDetailsPanel);
    PremiumLabel.Name := 'PremiumLabel';
    PremiumLabel.Left := 117;
    PremiumLabel.Top := 32;
    PremiumLabel.Width := 57;
    PremiumLabel.Height := 18;
    PremiumLabel.Alignment := taRightJustify;
    PremiumLabel.Caption := 'Premium';
    PremiumLabel.ElementClassName := 'Premium';
    PremiumLabel.ElementFont := efCSS;
    PremiumLabel.HeightStyle := ssAuto;
    PremiumLabel.HeightPercent := 100.000000000000000000;
    PremiumLabel.WidthPercent := 100.000000000000000000;
    PremiumLabel.DataField := 'NetPremium';
    PremiumLabel.DataSource := PolicySource;
    PremiumKindLabel.SetParentComponent(GadgetDetailsPanel);
    PremiumKindLabel.Name := 'PremiumKindLabel';
    PremiumKindLabel.Left := 117;
    PremiumKindLabel.Top := 56;
    PremiumKindLabel.Width := 84;
    PremiumKindLabel.Height := 18;
    PremiumKindLabel.Alignment := taRightJustify;
    PremiumKindLabel.Caption := 'PremiumKind';
    PremiumKindLabel.ElementClassName := 'Premium';
    PremiumKindLabel.ElementFont := efCSS;
    PremiumKindLabel.ElementPosition := epIgnore;
    PremiumKindLabel.HeightStyle := ssAuto;
    PremiumKindLabel.HeightPercent := 100.000000000000000000;
    PremiumKindLabel.WidthStyle := ssAuto;
    PremiumKindLabel.WidthPercent := 100.000000000000000000;
    PremiumKindLabel.DataField := 'PremiumPeriodDisplay';
    PremiumKindLabel.DataSource := PolicySource;
    DamageExcessLabel.SetParentComponent(GadgetDetailsPanel);
    DamageExcessLabel.Name := 'DamageExcessLabel';
    DamageExcessLabel.Left := 16;
    DamageExcessLabel.Top := 80;
    DamageExcessLabel.Width := 133;
    DamageExcessLabel.Height := 18;
    DamageExcessLabel.Caption := 'DamageExcessLabel';
    DamageExcessLabel.ElementFont := efCSS;
    DamageExcessLabel.HeightStyle := ssAuto;
    DamageExcessLabel.HeightPercent := 100.000000000000000000;
    DamageExcessLabel.WidthPercent := 100.000000000000000000;
    DamageExcessLabel.DataField := 'DamageExcess';
    DamageExcessLabel.DataSource := PolicySource;
    TheftExcessLabel.SetParentComponent(GadgetDetailsPanel);
    TheftExcessLabel.Name := 'TheftExcessLabel';
    TheftExcessLabel.Left := 24;
    TheftExcessLabel.Top := 127;
    TheftExcessLabel.Width := 114;
    TheftExcessLabel.Height := 18;
    TheftExcessLabel.Caption := 'TheftExcessLabel';
    TheftExcessLabel.ElementFont := efCSS;
    TheftExcessLabel.HeightStyle := ssAuto;
    TheftExcessLabel.HeightPercent := 100.000000000000000000;
    TheftExcessLabel.WidthPercent := 100.000000000000000000;
    TheftExcessLabel.DataField := 'TheftExcess';
    TheftExcessLabel.DataSource := PolicySource;
    LossOptionLabel.SetParentComponent(GadgetDetailsPanel);
    LossOptionLabel.Name := 'LossOptionLabel';
    LossOptionLabel.Left := 16;
    LossOptionLabel.Top := 160;
    LossOptionLabel.Width := 104;
    LossOptionLabel.Height := 18;
    LossOptionLabel.Caption := 'LossOptionLabel';
    LossOptionLabel.ElementFont := efCSS;
    LossOptionLabel.HeightStyle := ssAuto;
    LossOptionLabel.HeightPercent := 100.000000000000000000;
    LossOptionLabel.WidthPercent := 100.000000000000000000;
    LossOptionLabel.DataField := 'LossOption';
    LossOptionLabel.DataSource := PolicySource;
    DateOfPurchase.SetParentComponent(GadgetDetailsPanel);
    DateOfPurchase.Name := 'DateOfPurchase';
    DateOfPurchase.Left := 16;
    DateOfPurchase.Top := 248;
    DateOfPurchase.Width := 101;
    DateOfPurchase.Height := 18;
    DateOfPurchase.Caption := 'date-purchased';
    DateOfPurchase.ElementFont := efCSS;
    DateOfPurchase.ElementPosition := epIgnore;
    DateOfPurchase.HeightStyle := ssAuto;
    DateOfPurchase.HeightPercent := 100.000000000000000000;
    DateOfPurchase.WidthPercent := 100.000000000000000000;
    DateOfPurchase.DataField := 'DateOfPurchase';
    DateOfPurchase.DataSource := PolicySource;
    AddDatePurchased.SetParentComponent(GadgetDetailsPanel);
    AddDatePurchased.Name := 'AddDatePurchased';
    AddDatePurchased.Left := 170;
    AddDatePurchased.Top := 232;
    AddDatePurchased.Width := 25;
    AddDatePurchased.Height := 18;
    AddDatePurchased.Caption := 'Add';
    AddDatePurchased.ElementFont := efCSS;
    AddDatePurchased.ElementPosition := epIgnore;
    AddDatePurchased.HeightStyle := ssAuto;
    AddDatePurchased.HeightPercent := 100.000000000000000000;
    AddDatePurchased.WidthStyle := ssAuto;
    AddDatePurchased.WidthPercent := 100.000000000000000000;
    SetEvent(AddDatePurchased, Self, 'OnClick', 'AddDatePurchasedClick');
    AddSerialNumber.SetParentComponent(GadgetDetailsPanel);
    AddSerialNumber.Name := 'AddSerialNumber';
    AddSerialNumber.Left := 168;
    AddSerialNumber.Top := 184;
    AddSerialNumber.Width := 29;
    AddSerialNumber.Height := 18;
    AddSerialNumber.Caption := 'ADD';
    AddSerialNumber.ElementFont := efCSS;
    AddSerialNumber.ElementPosition := epIgnore;
    AddSerialNumber.HeightStyle := ssAuto;
    AddSerialNumber.HeightPercent := 100.000000000000000000;
    AddSerialNumber.WidthStyle := ssAuto;
    AddSerialNumber.WidthPercent := 100.000000000000000000;
    SetEvent(AddSerialNumber, Self, 'OnClick', 'AddSerialNumberClick');
    SerialNumLabel.SetParentComponent(GadgetDetailsPanel);
    SerialNumLabel.Name := 'SerialNumLabel';
    SerialNumLabel.Left := 14;
    SerialNumLabel.Top := 184;
    SerialNumLabel.Width := 138;
    SerialNumLabel.Height := 18;
    SerialNumLabel.Caption := 'IMEI /Serial  Number';
    SerialNumLabel.ElementFont := efCSS;
    SerialNumLabel.ElementPosition := epIgnore;
    SerialNumLabel.HeightStyle := ssAuto;
    SerialNumLabel.HeightPercent := 100.000000000000000000;
    SerialNumLabel.WidthPercent := 100.000000000000000000;
    SerialNumber.SetParentComponent(GadgetDetailsPanel);
    SerialNumber.Name := 'SerialNumber';
    SerialNumber.Left := 16;
    SerialNumber.Top := 208;
    SerialNumber.Width := 87;
    SerialNumber.Height := 18;
    SerialNumber.Caption := 'serial-number';
    SerialNumber.ElementFont := efCSS;
    SerialNumber.HeightStyle := ssAuto;
    SerialNumber.HeightPercent := 100.000000000000000000;
    SerialNumber.WidthPercent := 100.000000000000000000;
    SerialNumber.DataField := 'SerialNumber';
    SerialNumber.DataSource := PolicySource;
    TheftExcessCaption.SetParentComponent(GadgetDetailsPanel);
    TheftExcessCaption.Name := 'TheftExcessCaption';
    TheftExcessCaption.Left := 16;
    TheftExcessCaption.Top := 104;
    TheftExcessCaption.Width := 129;
    TheftExcessCaption.Height := 18;
    TheftExcessCaption.Caption := 'TheftExcessCaption';
    TheftExcessCaption.ElementFont := efCSS;
    TheftExcessCaption.ElementPosition := epIgnore;
    TheftExcessCaption.HeightStyle := ssAuto;
    TheftExcessCaption.HeightPercent := 100.000000000000000000;
    TheftExcessCaption.WidthPercent := 100.000000000000000000;
    memorypanel.SetParentComponent(Self);
    memorypanel.Name := 'memorypanel';
    memorypanel.Left := 248;
    memorypanel.Top := 344;
    memorypanel.Width := 177;
    memorypanel.Height := 133;
    memorypanel.HeightStyle := ssAuto;
    memorypanel.WidthStyle := ssAuto;
    memorypanel.BorderColor := clWhite;
    memorypanel.BorderStyle := bsNone;
    memorypanel.ChildOrder := 8;
    memorypanel.Color := clWhite;
    memorypanel.ElementFont := efCSS;
    memorypanel.ElementPosition := epIgnore;
    memorypanel.TabOrder := 6;
    MemoryGroup.SetParentComponent(memorypanel);
    MemoryGroup.Name := 'MemoryGroup';
    MemoryGroup.Left := 3;
    MemoryGroup.Top := 3;
    MemoryGroup.Width := 160;
    MemoryGroup.Height := 120;
    MemoryGroup.ElementClassName := 'custom-control custom-radio';
    MemoryGroup.HeightStyle := ssAuto;
    MemoryGroup.WidthStyle := ssAuto;
    MemoryGroup.Caption := '';
    MemoryGroup.ChildOrder := 6;
    MemoryGroup.Columns := 1;
    MemoryGroup.ElementButtonClassName := 'custom-control-input';
    MemoryGroup.ElementGroupClassName := 'modal-content';
    MemoryGroup.ElementLabelClassName := 'custom-control-label';
    MemoryGroup.ElementLegendClassName := 'h6';
    MemoryGroup.ElementFont := efCSS;
    MemoryGroup.ElementPosition := epIgnore;
    MemoryGroup.ItemIndex := 0;
    MemoryGroup.Role := '';
    MemoryGroup.TabOrder := 0;
    SetEvent(MemoryGroup, Self, 'OnChange', 'MemoryGroupChange');
    PolicySource.DataSet := MainData.PolicyData;
    PolicySource.Left := 24;
    PolicySource.Top := 120;
    CustomerSource.DataSet := MainData.CustomerData;
    BasketSource.SetParentComponent(Self);
    BasketSource.Name := 'BasketSource';
    BasketSource.DataSet := MainData.BasketData;
    BasketSource.Left := 24;
    BasketSource.Top := 184;
  finally
    QuoteSaveLabel.AfterLoadDFMValues;
    InputQueryDlg.AfterLoadDFMValues;
    AddGadgetButton.AfterLoadDFMValues;
    CoverGroup.AfterLoadDFMValues;
    PremiumGroup.AfterLoadDFMValues;
    QuotePanel.AfterLoadDFMValues;
    NetPremiumLabel.AfterLoadDFMValues;
    FirstNameEdit.AfterLoadDFMValues;
    LastNameEdit.AfterLoadDFMValues;
    MobileEdit.AfterLoadDFMValues;
    EmailEdit.AfterLoadDFMValues;
    CardButton.AfterLoadDFMValues;
    DirectDebitButton.AfterLoadDFMValues;
    DiscountLabel.AfterLoadDFMValues;
    PolicyTable.AfterLoadDFMValues;
    MarketingOK.AfterLoadDFMValues;
    GadgetDetailsPanel.AfterLoadDFMValues;
    ModelNameLabel.AfterLoadDFMValues;
    MemoryLabel.AfterLoadDFMValues;
    PremiumLabel.AfterLoadDFMValues;
    PremiumKindLabel.AfterLoadDFMValues;
    DamageExcessLabel.AfterLoadDFMValues;
    TheftExcessLabel.AfterLoadDFMValues;
    LossOptionLabel.AfterLoadDFMValues;
    DateOfPurchase.AfterLoadDFMValues;
    AddDatePurchased.AfterLoadDFMValues;
    AddSerialNumber.AfterLoadDFMValues;
    SerialNumLabel.AfterLoadDFMValues;
    SerialNumber.AfterLoadDFMValues;
    TheftExcessCaption.AfterLoadDFMValues;
    memorypanel.AfterLoadDFMValues;
    MemoryGroup.AfterLoadDFMValues;
    PolicySource.AfterLoadDFMValues;
    CustomerSource.AfterLoadDFMValues;
    BasketSource.AfterLoadDFMValues;
  end;
end;

end.

