unit CardPaymentPage;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,

  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  PaymentPage,
  Data.DB,
  WEBLib.DB,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  Vcl.Controls,
  WEBLib.DBCtrls,
  WEBLib.ExtCtrls,
  WEBLib.Grids,
  WEBLib.WebCtrls, CustomerPage;

type
  TCardPaymentForm = class(TPaymentForm)
    procedure PaybyAltLabelClick(Sender: TObject);
    procedure TermsOKClick(Sender: TObject);
  private
    [async]
    procedure PayByCard; async;
    { Private declarations }
  protected
    [async]
    procedure PayNow; async; override;
  public
    { Public declarations }
    [async]
    Procedure InitForm; async; override;

  protected procedure LoadDFMValues; override; end;

var
  CardPaymentForm: TCardPaymentForm;

implementation

{$R *.dfm}

uses
  MainDataModule,
  Gadget.Web.Consts;

{ TCreditCardForm }

procedure TCardPaymentForm.InitForm;
begin
  inherited;
//  if MainData.PolicyCount > 1 then
//    PlaceOrderButton.Caption := 'Insure my gadgets'
//  else
//    PlaceOrderButton.Caption := 'Insure my gadget';
  //never enabled - cant click the accept terms until the page is loaded?
  //PlaceOrderButton.Enabled := MainData.BasketDataAcceptTerms.Value;
  MarketingOKPanel.Visible := (not MainData.CustomerDataMarketingOK.Value);
end;

procedure TCardPaymentForm.PaybyAltLabelClick(Sender: TObject);
begin
  ChangeSitePage(TGadgetPage.gpDirectDebit);
end;

procedure TCardPaymentForm.PayByCard;
  procedure StripeTokenCallback(result: string);
  begin
    if result.StartsWith('Error') then
    begin
      ShowMessage('Token creation failed: ' + result);
      Exit;
    end;

    MainData.StripeSubscription(result,
      procedure(Success: boolean; ErrorMessage: string)
      begin
        if Success then
        begin
          ChangeSitePage(TGadgetPage.gpThankYou);
        end
        else
        begin
          if ErrorMessage <> '' then
            ShowMessage('There was an error with your subscription. ' + ErrorMessage)
        end;
      end
  );
  end;

begin
  {$IFDEF PAS2JS}
  asm
    var callback = function(result) {
      StripeTokenCallback(result);
    };
    GetToken(callback);
  end;
  {$ENDIF}
end;

procedure TCardPaymentForm.PayNow;
begin
  inherited;
  PayByCard;
end;

procedure TCardPaymentForm.TermsOKClick(Sender: TObject);
begin
  //PlaceOrderButton.Enabled := MainData.BasketDataAcceptTerms.Value;
end;

procedure TCardPaymentForm.LoadDFMValues;
begin
  inherited LoadDFMValues;


  PaybyAltLabel.BeforeLoadDFMValues;
  MobileEdit.BeforeLoadDFMValues;
  EmailEdit.BeforeLoadDFMValues;
  LastNameEdit.BeforeLoadDFMValues;
  MarketingOKPanel.BeforeLoadDFMValues;
  MarketingOK.BeforeLoadDFMValues;
  PlaceOrderButton.BeforeLoadDFMValues;
  TermsOK.BeforeLoadDFMValues;
  try
    SetEvent(PaybyAltLabel, Self, 'OnClick', 'PaybyAltLabelClick');
    SetEvent(MobileEdit, Self, 'OnExit', 'nil');
    SetEvent(EmailEdit, Self, 'OnExit', 'nil');
    SetEvent(LastNameEdit, Self, 'OnExit', 'nil');
    MarketingOKPanel.Left := 311;
    MarketingOKPanel.Top := 477;
    MarketingOK.TabOrder := 10;
    PlaceOrderButton.TabOrder := 13;
    TermsOK.Left := 239;
    TermsOK.Top := 480;
    TermsOK.TabOrder := 11;
  finally
    PaybyAltLabel.AfterLoadDFMValues;
    MobileEdit.AfterLoadDFMValues;
    EmailEdit.AfterLoadDFMValues;
    LastNameEdit.AfterLoadDFMValues;
    MarketingOKPanel.AfterLoadDFMValues;
    MarketingOK.AfterLoadDFMValues;
    PlaceOrderButton.AfterLoadDFMValues;
    TermsOK.AfterLoadDFMValues;
  end;
end;

end.
